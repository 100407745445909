<style scoped lang="scss">
  .customs_data_box {
    .toolbars {
      .otherbar {
        float: right;
        .selectservicer,.searchbtn  {
          display: inline-block;
        }
      }
    }
    .clearb {
      margin-bottom: 15px;
    }
  }
</style>

<template>
  <div class="customs_data_box">
    <div class="toolbars">
      <div class="otherbar">
        <time-to-time :showTitle="true" :isUnix="false" :defaultTime="'2019-09-20 11:27:21'" @commitPicker="getPicker" class="mrgl5 mrgr5 mrgb5"></time-to-time>
        <el-button class="searchbtn mrgb5" type="primary" plain size="small" @click="_search"><i class="fa fa-search"></i> 查询</el-button>
      </div>
    </div>
    <div class="clearb"></div>
    <echart id="customsEchart" title="关卡统计" width="100%" height="80vh" :option="options"></echart>
  </div>
</template>

<script>
import timeToTime from "@/components/TimeToTime"
import echart from "@/components/Echarts/Index";
export default {
  name: "customs",
  components: {
    timeToTime,
    echart
  },
  data() {
    return {
      options: {},
      listQuery: {
        serverId: null,
        beginTime: null,
        endTime: null
      }
    };
  },
  mounted() {
    this.setOptions();
  },
  methods: {
    setOptions() {
      let legendData = ["关卡通关率", "关卡(完美)通关率", "一次性通关率", "一次性(完美)通关率", "QTE释放频率", "玩家平均受击次数", "武器使用频率"]
      let xAxisData = []
      let series = []
      for (let i = 1; i <= 31; i++) {
        let time = "2019-08-" + (i < 10 ? "0" + i : i)
        xAxisData.push(time)
      }
      for (let i = 0; i < legendData.length; i++) {
        let temp = {
          name: legendData[i],
          type: "bar",
          stack: "basic",
          barWidth: "30%",
          barMaxWidth: "20px",
          barMinHeight: "10",
          data: []
        }
        for (let k = 0; k < 31; k++) {
          let item = Math.floor(Math.random() * 100)
          temp.data.push(item)
        }
        series.push(temp)
      }
      this.options = {
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: legendData
        },
        grid: {
          containLabel:true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        dataZoom: {
          show: true,
          start: 0,
          end: 100
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          axisLabel: {
            rotate: 30,
            interval: 0
          },
          data: xAxisData
        },
        yAxis: {
          type: "value",
          axisTick: {
            
          },
          axisLabel: {
            formatter: "{value}"
          }
        },
        series: series
      }
    },
    _search() {
      
    },
    getPicker(obj) {
      if (obj) {
        this.listQuery.beginTime = obj.startNumber ? obj.startTime : null
        this.listQuery.endTime = obj.endNumber ? obj.endTime : null
      }
      console.log(this.listQuery)
    }
  }
};
</script>