<style scope lang="scss">
  .c-timetotime-box {
    .title {
      color: #999;
    }
    .c-time-to-time {
      width: 80px;
    }
  }
</style>

<template>
  <span class="c-timetotime-box">
    <span v-if="showTitle" class="title">时间范围({{ unitText[unit] }})：</span>
    <el-input class="c-time-to-time mrgb5" :title="'开始' + unitText[unit] + '数(0代表全部)'" :placeholder="'第几' + unitText[unit]" 
      v-model="tStartNumber" :controls="false" size="small" @change="handleChange"></el-input>
    <span> 至 </span> 
    <el-input class="c-time-to-time mrgb5" :title="'结束' + unitText[unit] + '数(0代表全部)'" :placeholder="'第几' + unitText[unit]" 
      v-model="tEndNumber" :controls="false" size="small" @change="handleChange"></el-input>
  </span>
</template>

<script>
import moment from 'moment'
export default {
  name: "TimeToTime",
  props: {
    showTitle: {
      default: false,
      type: Boolean
    },
    unit: {
      default: "day",
      type: String
    },
    defaultTime: {
      default: "",
      type: String
    },
    isUnix: {
      default: true,
      type: Boolean
    },
    startNumber: {
      default: null,
      type: Number
    },
    endNumber: {
      default: null,
      type: Number
    }
  },
  watch: {
    tStartNumber() {
      //this.commitPicker()
    },
    tEndNumber() {
      //this.commitPicker()
    }
  },
  mounted() {
    
  },
  data() {
    return {
      unitArr: [ "second", "minute", "hour", "day", "week", "month", "year" ],
      unitText: { second: "秒", minute: "分", hour: "时", day: "日", week: "周", month: "月", year: "年" },
      tStartNumber: this.startNumber,
      tEndNumber: this.endNumber
    }
  },
  methods: {
    getValue() {
      let temp = {
        startTime: null,
        endTime: null,
        startNumber: this.tStartNumber,
        endNumber: this.tEndNumber
      }
      if (isNaN(this.tStartNumber) || parseInt(this.tStartNumber) <= 0) {
        this.tStartNumber = null
      }
      if (isNaN(this.tEndNumber) || parseInt(this.tEndNumber) <= 0) {
        this.tEndNumber = null
      }
      // 有默认开始时间
      if (this.defaultTime && moment(this.defaultTime).isValid()) {
        if (parseInt(this.tStartNumber) > 0) {
          temp.startTime = this.getFormatDate(moment(this.defaultTime).add(this.tStartNumber, this.unit + "s"), this.isUnix)
        }
        if (parseInt(this.tEndNumber) > 0) {
          temp.endTime = this.getFormatDate(moment(this.defaultTime).add(this.tEndNumber, this.unit + "s"), this.isUnix)
        }
      } else {
        temp.startTime = null
        temp.endTime = null
      }
      return temp
    },
    commitPicker() {
      this.$emit("commitPicker", this.getValue())
    },
    handleChange() {
      // 开始时间大于结束时间提示注意
      if (parseInt(this.tStartNumber) > parseInt(this.tEndNumber)) {
        this.alert("当前开始时间大于结束时间，请注意核对!")
      }
      this.commitPicker()
    }
  }
}
</script>
